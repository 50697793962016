.btn{
  background-color: $keyColor;
  color: #fff;
  display: inline-block;
  margin-top: 2em;
  padding: 10px 15px;
  &:hover{
    opacity: 0.5;
  }
}
