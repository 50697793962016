//全体共通で使うCSS定義
body{
  word-wrap: break-word;
  font-family: Arial;
  color: $textColor;
}

a{
  text-decoration: none;
}
p{
  line-height: 1.5;
  margin-bottom: 1em;
}
h1,h2,h3,h4,h5{
  font-family: $times;
  font-weight: normal;
}
