html.scroll-prevent{
  /*動き固定*/
  position: fixed;
  /*奥行きを管理*/
  z-index: -1;
  /*下2つで背景を元のサイズのまま表示することができる*/
  width: 100%;
  height: 100%;
}

.gmenu{
  display: inline;
  @include mq-max(lg){
    display: none;
    &.active{
      display: block;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      position: absolute;
      z-index: 1000;
    }
  }
  #gnavi{
    @include mq-max(lg){
      height: 100vh;
      margin-top: 152px;
    }
    ul{
      text-align: center;
      li{
        display: inline-block;
        margin-right: 25px;
        &:last-child{
          margin-right: 0;
        }
        &:nth-last-child(2){
          margin-right: 0;
        }
        @include mq-max(lg){
          display: inline;
          margin: 24px auto;
        }
        a{
          color: #fff;
          text-shadow: 0 0 5px #081d3b;
          &.current span{
            border-bottom: solid 1px $keyColor;
            padding-bottom: 4px;
          }
          @include mq-max(lg){
            display: block;
            padding: 16px;
            font-size: 18px;
          }
        }
        &.has-submenu {
          position: relative;
          ul {
            position: absolute;
            padding: 10px;
            top: 0;
            left: -24px;
            opacity: 0;
            transition: top .15s,
                        opacity .15s;
            @include mq-max(md) {
              position: relative;
              opacity: 1;
              left: 0;
            }
            a {
              background-color: rgba(25,35,45,0);
              padding: 10px;
              @include mq-max(md) {
                padding: 16px;
              }
            }
          }
          .animated &{

            &:hover {
              ul {
                a {
                  background-color: rgba(25,35,45,0.4);
                }
              }
            }
          }
          &:hover {
            ul {
              opacity: 1;
              top: 100%;
            }
          }
        }
        &.action-contact{
          width: 140px;
          height: 50px;
          line-height: 50px;
          background-color: $keyColor;
          display: block;
          font-size: 14px;
          position: absolute;
          top:0;
          right: 0;
          a {
            &:before {
              content: '';
              position: relative;
              display: inline-block;
              background-image: url('/inc/image/common/ico_contact.png');
              background-repeat: no-repeat;
              background-size: contain;
              width: 18px;
              height: 18px;
              margin-right: 5px;
              top: 3px;
            }
          }
          &.brochure {
            right: 140px;
            border-right: 1px solid darken($keyColor, 7.5%);
            @include mq-max(lg) {
              right: 0;
              border-right: none;
            }
            a {
              &:before {
                background-image: url('/inc/image/common/ico_brochure.png');
              }
            }
          }
          @include mq-max(lg){
            position: relative;
            margin: 16px auto 0;
            height: auto;
          }
          a{
            display: block;
            text-shadow: none;
            @include mq-max(lg){
              padding: 0;
            }
          }
        }
      }
    }
  }
}

// ハンバーガーアイコン
.navBtnArea{
  display: none;
  position: absolute;
  background: #d7053d;
  right: 0;
  padding: 18px;
  z-index: 1100;
  cursor: pointer;
  @include mq-max(lg){
    display: block;
  }

  .navToggle,
  .navToggle span{
    transition: all 0.7s;
  }

  .navToggle{
    width: 18px;
    height: 18px;

    div{
      position: relative;
    }

    span{
      display: block;
      position: absolute;
      width: 100%;
      border-bottom: solid 2px #FFF;
      -webkit-transition: .35s ease-in-out;
      -moz-transition: .35s ease-in-out;
      transition: .35s ease-in-out

      &:nth-child(1){
        top: 0;
      }
      &:nth-child(2){
        top: 7px;
      }
      &:nth-child(3){
        top: 14px;
      }
    }
  }

  &.active{
    span{
      &:nth-of-type(1) {
        transform: translateY(7px) rotate(-45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(-7px) rotate(45deg);
      }
    }
  }
}

//言語切り替え EN/JPのリンク
.gmenu{
  #gnavi{
    .outer{
      padding-left: 30px;
        @include mq-max(lg){
          padding-left: 0;
        }
    }

      .lang-btn{
        display: inline-block;
        position: absolute;
        top: 8px;
        right: 296px;
        color: #fff;
        background-color: rgba(25,35,45,0.4);
          @include mq-max(lg){
            padding-left: 0;
            right: 54px;
          }
          @include mq-max(md){
            padding-left: 0;
            margin: 0;
          }
          li {
            display: block;
            &.lang {
              display: block;
              cursor: pointer;
              position: relative;
              z-index: 10;
                @include mq-max (lg) {
                  margin: 0 auto;
                }
              &:before {
                content: '';
                background-image: url('/inc/image/common/ico_lang.png');
                background-size: contain;
                position: absolute;
                width: 16px;
                height: 16px;
                left: 10px;
                top: calc(50% - 8px);
              }
              & + ul {
                position: absolute;
                top: 0;
                opacity: 0;
                transition: 0.15s opacity,
                            0.15s top;
                background-color: rgba(25,35,45,0.4);
                visibility: hidden;
              }
            }
            a {
              display: inline-block;
              padding: 8px 20px 8px 32px;
              transition: 0.15s border-bottom;
            }
          & + ul {
              background-color: rgba(55,65,75,0.4);
              span {
                border-bottom: 1px solid transparent;
                transition: border-bottom 0.1s;
                &:hover {
                  border-bottom: 1px solid #FFF;
                }
              }
              li {
                @include mq-max(lg) {
                  margin: 0;
                }
              }
          }

        }
        &:hover {
          li + ul {
            position: absolute;
            top: 100%;
            opacity: 1;
            visibility: visible;
          }
        }

        .jp {
            a {
              font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
              padding: 8px 24px 8px 32px;
                @include mq-max(lg) {
                  padding: 4px 16px 4px 32px;
                  span {
                    font-size: 16px;
                  }
                }
            }
            > span {
              padding: 8px 24px 8px 32px;
            }
        }
      }

  }
}
