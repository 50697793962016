.footer-info{
  padding: 70px 20px 50px;
  background-image: url(/inc/image/common/img_footer-info.jpg);
  background-size: cover;
  background-attachment: fixed;
  @include mq-max(md){
    background-attachment: scroll; // モバイル端末で背景画像不具合の対策
  }
  @include mq-max(sm){
    background-attachment: scroll; // モバイル端末で背景画像不具合の対策
  }
  h2{
    max-width: 550px;
    margin: 0 auto;
    margin-bottom: 30px;
    color: #fff;
    text-shadow: 0 0 5px #000;
    font-size: 56px;
    font-weight: normal;
    text-align: center;
    border-bottom: solid 1px $keyColor;
    @include mq-max(md){
      font-size: 50px;
    }
  }
  p{
    color: #fff;
    text-shadow: 0 0 5px #000;

    max-width: 470px;
    margin: 0 auto;
    text-align: center;
    line-height: 1.5;
    a.more{
      color: #fff;
      margin-top: 65px;
    }
  }
  &.qa{
    background-image: url(/inc/image/common/img_footer-qa.jpg);
    h2,p,a.more{
      color: $textColor;
      text-shadow:none;

    }
  }
}

.footer-contact{
  padding: 70px 20px 0;
  text-align: center;
  h2{
    font-size: 56px;
    display: inline-block;
    border-bottom: solid 1px $keyColor;
    margin-bottom: 25px;
    @include mq-max(md){
      font-size: 50px;
    }
  }
  p.desc{
    max-width: 480px;
    margin: 0 auto;
    text-align: left;
  }
  .contact-box{
    padding-top: 50px;
    &+div {
      @include mq-max(md){
      margin-top: -70px;
      }
    }
  }
  .tel-link{
    display: inline-block;
    color: $textColor;
    font-size: 36px;
    font-weight: bold;
    border-bottom: solid 1px $keyColor;
  }
  .mail-link{
    display: block;
    color: $textColor;
    margin-top:20px;
    margin-bottom: 30px;
  }
  .logo{
    display: block;
    margin: 0 auto;
    margin-top: 20px;
  }
  .address{
    margin-top: 20px;
    text-align: left;
  }
  .grid {
    margin-bottom: 0;
  }
  &--en {
    .contact-box {
      padding: 0 10px 20px;
    }
    .address {
      text-align: center;
    }
  }
  &__btn {
    width: 100%;
    max-width: 550px;
  }
}





footer{
  //text-align: center;
  .attention{
    padding: 2em 0;
    p{
      font-size: 80%;
    }
  }
  ul{
    display: inline-block;
    margin-bottom: 10px;
    @include mq-max(sm){
      display: block;
      padding: 0 20px;
      margin-bottom: 20px;
    }
    li{
      display: inline-block;
      padding-bottom: 4px;
      margin-right: 25px;
      @include mq-max(sm){
        display: block;
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-bottom: 0;
        margin-right: 0;
        border-left: solid 1px #dadada;
        border-right: solid 1px #dadada;
        border-bottom: solid 1px #dadada;
        &:first-child{
          border-top: solid 1px #dadada;
        }
      }
      &:last-child{
        margin-right: 0;
      }
      a{
        color: $textColor;
        display: block;
      }
      &.current{
        border-bottom: solid 1px $keyColor;
      }
    }
  }
  .footer-logo{
    text-align: center;
    border-top: solid 1px #dadada;
    padding: 15px 0;
    img{
      width: 100px;
    }
  }
  .policy {
    font-size: 80%;
    display: flex;
    justify-content: flex-end;
    padding-right: 100px;
    @include mq-max(md) {
      justify-content: center;
      padding-right: 0;
    }
    @include mq-max(sm) {
      font-size: 70%;
      padding-left: 0;
    }
    li {
      border: none;
      &:first-child {
        border-top: none;
      }
      &:not(:last-of-type) {
        @include mq-max(sm) {
          margin-right: 20px;
        }
      }
      @include mq-max(sm) {
        width: auto;
      }
    }
  }
  .copyright{
    display: block;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 11px;
  }
}
