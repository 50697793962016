table{

    &.table {
      width: 100%;
      border-collapse: collapse;
        th,
        td {
          padding: 10px;
          // border: 1px solid $gray;
          vertical-align: middle;
          // @include mq-max(md) {
          //   display: block;
          // }
        }
        thead {
          th, td {
            border-bottom: 2px solid $textColor;
          }
        }
        tbody {
          th, td {
            border-bottom: 1px solid $textColor;
          }

        }
    }

    &.table-style-default {
      th {
        font-weight: normal;
      }
      thead {
        th {
          text-align: right;
          width: 100%;
        }
      }
      tbody {
        th {
          text-align: left;
        }
        td {
          text-align: right;
        }
      }
    }

    &.table-style-list {
      thead {
        th {
          min-height: 40px;//
          display: block;//
        }
      }
      tbody {
        tr {
          &:last-child {
            th, td {
              // border-bottom: none;
            }
          }
        }
      }

    }
}
