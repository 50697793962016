.qa-area{
//  QandAページ以外に　qa-areaが使われる場合は、QandAの個別scssを作成する
  @include mq-max(md){
  margin-top: -100px;
  }
  dl{
    dt{
      font-family: $times;
      font-size: 22px;
    	line-height: 1.6em;
    	position: relative;
    	display: block;
    	margin: 0 0 1em 0;
      padding: 35px 100px 35px 140px;
      min-height: 80px;
    	cursor: pointer;
    	border-radius: 0.5em;
      border-bottom: solid 1px $gray;
      &:hover{
        opacity: 0.5;
      }
      @include mq-max(sm){
        padding-left: 40px;
        padding-right: 40px;
        font-size: 19px;
      }
      &:before{
        font-family: $times;
        font-size: 80px;
        color: $gray;
        position: absolute;
        left: 22px;
        content: 'Q.';
        @include mq-max(sm){
          font-size: 30px;
          left: 5px;
        }
      }
      &:after{
        font-size: 1.7em;
        font-weight: bold;
        line-height: 1em;
        position: absolute;
        top: 50%;
        margin-top: -0.5em;
        right: 0;
        content: '+';
        color: $keyColor;
        display: inline-block;
        width: 1em;
        height: 1em;
        @include mq-max(sm){
          right: 5px;
        }
      }
      &.open:after{
        content: "×";
      }
    }
    dd{
      position: relative;
      padding: 0 100px 7em 140px;
      background-image: url(/inc/image/common/img_qa-logo.png);
      background-position: bottom right;
      @include mq-max(sm){
        padding-left: 40px;
        padding-right: 40px;
        background-size: 150px;
      }
      &:before{
        font-family: $times;
        font-size: 80px;
        position: absolute;
        padding: 0;
        top: -14px;
        left: 22px;
        content: 'A.';
        color: #eb829e;
        @include mq-max(sm){
          font-size: 30px;
          left: 5px;
          top:-6px;
        }
      }
    }
  }
}
