html{
  &.iphone,&.ipad,&.android{
    header,.mainvisual,.footer-info{
      background-attachment: scroll !important;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover; 
    }
  }
}
