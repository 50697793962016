/*
モジュール系おまとめファイル
*/

// ヘッダー
@import "header";

// フッター
@import "footer";

// グローバルナビゲーション
@import "navigation";

 // ページトップに戻る
@import "pageTop";

 // ボタン
@import "button";

// タブ
@import "tab";

//テーブル
@import "table";

//ぱんくず
@import "topicspath";

//リンク
@import "link";

//テキスト
@import "text";

//タグ
@import "tag";

//三角形セクション
@import "triangle-section";

//qa
@import "qa";

//リスト
@import "list";

//パララックスさせない
@import "nonparallax";

//slick
@import "slick";
@import "slick-theme";
