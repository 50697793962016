.triangle-section{
  margin-top: -18vw;
  .title-triangle{
    width: 100%;
    padding-top: 5vw;
    padding-bottom: 10vw;
    margin-bottom: 45px;
    background-image: url(/inc/image/common/bg_triangle_cg.svg);
    background-size: cover;
    background-position: top center;
    @include mq-max(md){
      padding-bottom: 20vw;
    }
    &.bg-white{
      background-image: url(/inc/image/common/bg_triangle_cw.svg);
    }
    h1,h2{
      text-align: center;
      font-family: $times;
      color: $textColor;
      font-size: 72px;
      line-height: 1;
      margin-bottom: 45px;
      @include mq-max(md){
        font-size: 58px;
        margin-top: 35px;
        line-height: 45px;
        margin-bottom: 20px;
      }
      span{
        font-size: 24px;
        font-weight: normal;
      }
      img{
        margin-left: 15px;
      }
    }
    p{
      max-width: 595px;
      text-align: center;
      padding: 0 20px;
      margin: 0 auto;
      line-height: 1.5;
    }
  }

  section{
    &.section-content{
      .bg-section{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        position: relative;
        padding-bottom: 16vw;
        padding-top: 7vw;
        margin-top: -10vw;
        @include mq-max(md){
          padding-bottom: 25vw;
          padding-top: 17vw;
          margin-top: -17vw;
        }
        @include mq-max(sm){
          padding-top: 20vw;
        }
        .container{
          min-height: 520px;
          @include mq-max(md){
          min-height: auto;
          }
        }
        .title{
          padding-left: 50%;
          margin-bottom: 48px;
          @include mq-max(md){
            padding-left: 0;
            padding-top: 5px;
            position: relative;
          }
          .tag{
            margin-left: 50px;
            @include mq-max(md){
              position: absolute;
              top: 0;
              margin-top: -35px;
              margin-left: 0;
            }
          }
          .midashi{
            position: relative;
            font-family: $times;
            font-size: 45px;
            color: $textColor;
            margin-top: 18px;
            margin-bottom: 15px;
            padding-bottom: 20px;
            padding-left: 50px;
            line-height: 1;
            border-bottom: solid 1px $keyColor;
            &:before{
              content: "";
              width: 9px;
              height: 9px;
              background-color: $keyColor;
              display: block;
              position: absolute;
              bottom: 0;
              margin-bottom: -4.5px;
              left: 0;
              margin-left: -4.5px;
            }
            @include mq-max(md){
              font-size: 35px;
              padding-left: 0;
              margin-left: 0;
              margin-right: 0;
            }
          }
          p{
            margin-left: 50px;
            @include mq-max(md){
              margin-left: 0;
            }
          }
        }
        .image{
          width: 50%;
          position: absolute;
          top: 6vw;
          left: 0;
          border: solid 1px $keyColor;
          border-left:none;
          padding-right: 10px;
          padding-top: 10px;
          z-index: 1000;
          img{
            width: 100%;
            margin-bottom: -15px;
          }
          @include mq-max(md){
            position: static;
            width: 100%;
            padding: 0;
            border: none;
            img{
              margin: 0;
            }
          }
        }
        .image_wdh_100 {
          width: 100%;
        }
        .desc{
          padding-left: 50%;
          @include mq-max(md){
            padding-left: 0;
          }
          p{
            line-height: 1.5;
            margin-left: 50px;
            @include mq-max(md){
              margin-left: 0;
              margin-top: 30px;
              padding: 0;
            }
            .more{
              margin-top: 30px;
            }
          }
        }
        &.reverse{
          .title{
            padding-left: 0;
            padding-right: 50%;
            @include mq-max(md){
              padding-right: 0;
            }
            .tag,p{
              margin-left: 0;
            }
            .midashi{
              padding-left: 0;
              &:before{
                left: auto;
                right: 0;
                margin-right: -4.5px;
                @include mq-max(md){
                  left: auto;
                  right: auto;
                  margin-left: -4.5px;
                }
              }
            }
          }
          .desc{
            padding-left: 0;
            padding-right: 50%;
            @include mq-max(md){
              padding-right: 0;
            }
            p{
              margin-left: 0;
              padding-right: 50px;
              @include mq-max(md){
                padding-right: 0;
              }
            }
          }
          .image{
            left: auto;
            right: 0;
            padding-right: 0;
            padding-left: 10px;
            border-right: none;
            border-left: solid 1px $keyColor;
            @include mq-max(md){
              padding: 0;
              border: none;
            }
          }
        }
        &.bg-white{
          background-color: $gray;
          background-image: url(/inc/image/common/bg_triangle_rw.svg) !important;
          @include mq-max(md){
            background-image: url(/inc/image/common/bg_triangle_cw.svg) !important;
          }
        }
        &.bg-gray{
          background-color: #fff;
          background-image: url(/inc/image/common/bg_triangle_lg.svg) !important;
          @include mq-max(md){
            background-image: url(/inc/image/common/bg_triangle_cg.svg) !important;
          }
        }
        &:last-child{
            padding-bottom: 12vw;
        }
      }
    }
  }
  &.small-width{
    .section-content{
      .bg-section{
        padding-top: 0;
        .container{
          .title{
            padding: 0;
            width: 45%;
            float: right;
            margin-bottom: 20px;
            .midashi{
              padding-left: 35px;
            }
          }
          .image{
            position: static;
            width: 55%;
            float: left;
            border: solid 1px $keyColor;
            padding-right: 0;
            img{
              margin-left: -12px;
            }
          }
          .desc{
            padding: 0;
            width: 45%;
            float: right;
            p{
              margin-left: 35px;
              margin-top: 0.8em;
              &:first-child{
                margin-top: 0;
              }
              img{
                float: left;
                padding: 10px 10px 10px 0;
                @include mq-max(md){
                  float: none;
                  display: block;
                }
              }
            }
          }
        }
        &.reverse{
          .title{
            padding: 0;
            float: left;
            .midashi{
              padding-left: 0;
              padding-right: 35px;
            }
          }
          .image{
            float: right;
            padding-left: 0;
            img{
              margin-left: 12px;
            }
          }
          .desc{
            padding: 0;
            float: left;
            p{
              margin-left: 0;
              margin-right: 35px;
              padding: 0;
            }
          }
        }
        &.bg-white-center,&.bg-gray-center{
          padding-top: 7vw;
          background-color: $gray;
          background-image: url(/inc/image/common/bg_triangle_cw.svg);
          .title,.desc,.image{
            float: none;
            width: 100%;
          }
          .title{
            .midashi{
              border: none;
              padding: 0;
              text-align: center;
              &:before{
                display: none;
              }
            }
          }
          .desc{
            max-width: 560px;
            margin: 0 auto;
          }
          .image{
            border: none;
            padding: 0;
            margin-top: 35px;
            img{
              margin: 0;
            }
          }
        }
        &.bg-gray-center{
          background-color: #fff;
          background-image: url(/inc/image/common/bg_triangle_cg.svg);
          .underline{
            border-color: #fff;
          }
        }
        @include mq-max(sm){
          .container{
            .title,.desc,.image{
              float: none;
              width: 100%;
            }
            .title{
              .midashi{
                padding-left: 0;
                padding-right: 0;
                margin: 0;
              }
            }
            .image{
              padding: 0;
              border: none;
              margin-bottom: 30px;
              img{
                margin: 0;
              }
            }
            .desc{
              p{
                padding: 0;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
