.tab-list,
.sp-tab-list {
  transform: translateY(80px);
  @include mq-max(md) {
    margin: 0 20px 130px;
    transform: translateY(70px);
    .android &{
      transform: translateY(70px);
      margin: 0 20px 155px;
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include mq-max(md) {
      flex-direction: column;
    }
  }
  li {
    list-style-type: none;
    @include mq-max(md) {
      width: 100%;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1.2em;
      padding: 12px 4px;
      color: #FFF;
      background-color: #CCC;
      transition: .1s background-color;
      margin-right: 1px;
      min-height: 4em;
      width: 145px;
      text-align: center;
      &[target="_blank"] {
        &:after {
          content: '\f24d';
          font-family: 'fontawesome';
          margin-left: 5px;
        }
      }
      @include mq-max(md) {
        width: 100%;
      }
    }
    @media all and (-ms-high-contrast:none){
      *::-ms-backdrop, a {
        line-height: 1.2em;
      }
      *::-ms-backdrop, .ms-lh {
        line-height: 2.4em;
        max-height: 4em;
      }
    }
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.2em;
        padding: 12px 8px;
        color: #FFF;
        background-color: #CCC;
        transition: .1s background-color;
        margin-right: 1px;
        min-height: 4em;
        width: 145px;
        text-align: center;
        cursor: pointer;
        @include mq-max(md) {
          width: 100%;
        }
    }
    &.is-active {
      a,span {
        background-color: $keyColor;
        @include mq-max(md) {
          position: relative;
          background-color: $keyColor!important;
          &:before {
            content: "▼";
            color: #FFF;
            position: absolute;
            left: auto;
            right: 10px;
          }
        }
      }
    }
    &:hover{
      a,span{
        background: #aaa;
      }
    }
  }
}

.tab-pane {
  display: none;
  padding-top: 30px;
    @include mq-max(md) {
      padding-top: 210px;
    }
  &.is-active {
    display: block;
  }
}
