header{
  width: 100%;
  //ä¸‹å±¤
  background-image: url(/inc/image/top/bg_mainvisual.jpg);
  background-size: cover;
  background-position: 50% 100%;
  background-attachment: fixed;
  height: 22vw;
  padding-top: 25px;
  @include mq-max(lg){
    height: 25vw;
    background-position: 50% 50%;
    background-attachment: scroll; // モバイル端末で背景画像不具合の対策
  }
  @include mq-max(md){
    background-attachment: scroll; // モバイル端末で背景画像不具合の対策
  }
  @include mq-max(sm){
    padding-top: 0;
    height: 150px;
    background-attachment: scroll; // モバイル端末で背景画像不具合の対策
  }
  a.logo{
    img{
      position: absolute;
      width: 120px;
      top: 25px;;
      left: 20px;
    }
  }
}

//ヘッダーグロナビ　スライドダウンアニメーション設定
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@media screen and (min-width: 1001px){
//ヘッダーグロナビ固定（トップページのみ）
.fixedheader {
  position: fixed;
  margin-top: 25px;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);
  #gnavi {
    margin: 15px 0 15px;
  }
}
//ヘッダーグロナビ固定（トップページ以外のページ）
.fixedheader_not_top,
.fixedheader_not_top_ja {
  position: fixed;
  left: 0;
  right: 0;
  margin-top: -25px;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);
  padding-bottom: 2.4px;
  #gnavi {
    margin: 15px 0 15px;
  }
}
//ヘッダーグロナビ固定　ロゴ位置の調整
.fixedheader_adjust {
   margin-top: -23px;
}
//ヘッダー固定　日本語ボタンの位置調整
.fixedheader_adjust_ja {
  margin-top: -3px;
}
}