//リンク
a.more{
  position: relative;
  padding-left: 25px;
  padding-top: 5px;
  color: $textColor;
  &:before{
    background: $keyColor;
    content: "";
    height: 20px;
    position: absolute;
    width: 2px;
    top: 3px;
    left: 9px;
  }
  &:after{
    background: $keyColor;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    top: 12px;
    width: 20px;
  }
  &.big{
    padding: 0;
    padding-top: 35px;
    display: inline-block;
    &:before{
      height: 34px;
      width: 3px;
      top: 0;
      left: 23px;
    }
    &:after{
      height: 3px;
      left: 7px;
      top: 17px;
      width: 34px;
    }
  }
}
