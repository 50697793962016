//サイトレイアウト構造を記述

#container{}
#main{}

.container{
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  padding: 0 20px;
}

.clearfix:after {
  content: "";
  clear: both;
  display: block;
}

img{
  max-width: 100%;
}

footer,#f-contact{
  background: #fff;
}