//テキスト
.taR{
  text-align: right;
}

.taL{
  text-align: left;
}

.taC{
  text-align: center;
}

.fl-strong{
  &:first-letter{
    color: $keyColor;
  }
}

.fl-midashi{
  font-size: 26px;
  margin-top: 10px;
  margin-bottom: 15px;
  &:first-letter{
    color: $keyColor;
    font-size: 39px;
  }
}

.underline{
  font-size: 24px;
  border-bottom: solid 1px $gray;
  margin-top: 10px;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.inline-underline{
  border-bottom: solid 1px $keyColor;
  padding-bottom: 5px;
}

.midashi-center{
  font-size: 54px;
  text-align: center;
  margin-bottom: 20px;
}

.text-bold {
  font-weight: bold;
}

.pc_br{
  @include mq-max(md){
    display: none;
  }
}
